<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <template #activator="{ on, attrs }">
      <v-app-bar-nav-icon
        v-bind="attrs"
        class="d-inline-block d-md-none"
        v-on="on"
      />
    </template>
    <v-card>
      <v-container>
        <v-row>
          <v-col class="text-right">
            <v-btn
              class="mx-2"
              icon
              @click="dialog = false"
            >
              <v-icon size="36">
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-container>
            <v-row>
              <v-col cols="6">
                <span><v-icon class="mr-2 red--text">mdi-movie</v-icon>Movies</span>
                <v-list flat>
                  <v-list-item to="/movie/release-calendar">
                    <v-list-item-title>Release Calendar</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/movie/top-rated">
                    <v-list-item-title>Top 250 Movies</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/movie/most-popular">
                    <v-list-item-title>Most Popular</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/movie/over-years">
                    <v-list-item-title>Top Over Years</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list flat>
                  <span><v-icon class="mr-2 red--text">mdi-controller</v-icon>Games</span>
                  <v-list-item to="/game/release-calendar">
                    <v-list-item-title>Release Calendar</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/game/top-rated">
                    <v-list-item-title>Top 250 Games</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/game/most-popular">
                    <v-list-item-title>Most Popular</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/game/over-years">
                    <v-list-item-title>Top Over Years</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6">
                <span><v-icon class="mr-2 red--text">mdi-television</v-icon>TV Shows</span>
                <v-list flat>
                  <v-list-item to="/tv/release-calendar">
                    <v-list-item-title>Release Calendar</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/tv/top-rated">
                    <v-list-item-title>Top 250 TV Shows</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/tv/most-popular">
                    <v-list-item-title>Most Popular</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/tv/over-years">
                    <v-list-item-title>Top Over Years</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list flat>
                  <span><v-icon class="mr-2 red--text">mdi-book</v-icon>Books</span>
                  <v-list-item to="/book/top-rated">
                    <v-list-item-title>Top 250 Books</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/book/most-wanted">
                    <v-list-item-title>Growing Popularity</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/book/most-reading">
                    <v-list-item-title>Most Reading</v-list-item-title>
                  </v-list-item>
                  <v-list-item href="/author/top-rated">
                    <v-list-item-title>Top 150 Authors</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list flat>
                  <span><v-icon class="mr-2 red--text">mdi-post</v-icon>News</span>
                  <v-list-item to="/news?type=movie">
                    <v-list-item-title>Movie News</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/news?type=tv">
                    <v-list-item-title>TV Show News</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/news?type=book">
                    <v-list-item-title>Book News</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/news?type=game">
                    <v-list-item-title>Game News</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list flat>
                  <span><v-icon class="mr-2 red--text">mdi-text-search</v-icon>Other</span>
                  <v-list-item href="/discover">
                    <v-list-item-title>Discover</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/shaker">
                    <v-list-item-title>Shaker</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/spotlight">
                    <v-list-item-title>Spotlight</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NavigationToolbar",
  data: () => ({
    dialog: false,
  }),
  watch: {
    "$route.path" () {
      this.dialog = false;
    },
  },
};
</script>
